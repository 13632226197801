// modules
import React, { Component } from 'react';
// styles
import './button.scss';

class Button extends Component {

  render() {

    const {
      href,
      classes,
      target,
      text
    } = this.props;

    return (
      <a
        href={href}
        target={target}
        rel={target !== "_blank" ? "" : "external noopener noreferrer"}
        className={"button " + classes}>
          <span>{text}</span>
      </a>
    )

  }

}

export default Button;
