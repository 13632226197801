// modules
import React, { Component } from 'react';
// components
import Eyebrow from '../atoms/Eyebrow';
// styles
import './meal.scss';

class Meal extends Component {

  render() {

    // restaurant details
    const {
      name,
      details
    } = this.props.meal;

    return (
      <div className="meal">
        <p className="meal__name">{name}</p>
        <Eyebrow text={details} />
      </div>
    )

  }

}

export default Meal;
