// modules
import React from 'react';
import { Route, Switch } from 'react-router-dom';
// pages
import Delivery from './components/pages/Delivery';
import Collect from './components/pages/Collect';
import Declined from './components/pages/Declined';
// styles
import './scss/app.scss';

const App = () => {
  return (
    <React.Fragment>
      <Switch>
        <Route exact path="/" component={Delivery}></Route>
        <Route exact path='/order-collect' component={Collect} />
        <Route exact path='/order-declined' component={Declined} />
      </Switch>
    </React.Fragment>
  );
}

export default App;
