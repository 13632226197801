// modules
import React, { Component } from 'react';
// components
import Countdown from 'react-countdown';
// styles
import './counter.scss';

class Counter extends Component {

  currentOffsetArray = [];

  state = {
    dashArray: 270,
    colors: {
      minor: "#00C853",
      medium: "#FFAC00",
      critical: "#FF2500"
    }
  }

  render() {

    const {
      dashArray,
      colors
    } = this.state;

    const {
      original,
      counter,
      accept,
      accepted,
      hold,
      time
    } = this.props;

    // renderClock callback with conditions
    const renderClock = ({ seconds, completed }) => {
      if (completed) {
        setTimeout(() => {
          this.props.history.push('./order-declined');
        }, 500);
        return (
          <span>0</span>
        )
      } else {
        return (
          <span>{seconds < 10 ? '0' + seconds : seconds}</span>
        )
      }
    };

    // renderCircle callback with conditions
    const renderCircle = ({ total, completed }) => {
      let increment = dashArray / 100; // 2.7 = increment per %
      let percentage = (total / original) * 100; // (milliseconds / prep time) * 100
      let offset = dashArray - (increment * percentage); // 270 - (2.7 * %) / 270 - (2.7 * ((5000 / 10000) * 100)) = 135 (50%)

      if (accept === true) {

        // get current (last) offset
        let intervalOffsets = dashArray - offset; // 270 - current offset
        this.currentOffsetArray.push(intervalOffsets);

        let newIncrement = (dashArray - this.currentOffsetArray[0]) / 100; // increment per %
        let newPercentage = (hold / ((time + 2) * 1000)) * 1000; // (holding time / 3000 millisseconds) * 1000
        let newOffset = (dashArray - this.currentOffsetArray[0]) - (newIncrement * newPercentage); // (270 - currentoffset) - (increment * %)

        // if circle is full
        if (newOffset <= 0) {
          newOffset = 0;
        }

        return (
          <circle className="percentage" cx="50%" cy="50%" r="43%" style={{strokeDashoffset: -newOffset, stroke: colors.minor }}></circle>
        )
        
      } else {

        // clear arrays
        this.currentOffsetArray = [];

        // colors
        let hexa;
        if (completed || total <= 5000) {
          hexa = colors.critical;
        } else if (total <= (original / 2)) {
          hexa = colors.medium
        } else {
          hexa = colors.minor
        }

        return (
          <circle className="percentage" cx="50%" cy="50%" r="43%" style={{ strokeDashoffset: -offset, stroke: hexa }}></circle>
        )

      }
    };

    return (
      <div className="counter">
        <div className="counter__pulse" style={ !accepted ? { animationIterationCount: (time * 10) + 1} : {}}></div>
        <div className="counter__percentage">
          <svg viewBox="0 0 100 100">
            <defs>
              <radialGradient id="myGradient">
                <stop offset="70%" stopColor="#1E2021" />
                <stop offset="90%" stopColor="#171819" />
              </radialGradient>
            </defs>
            <circle className="full" cx="50%" cy="50%" r="43%" fill="url('#myGradient')"></circle>
            <Countdown date={Date.now() + counter} intervalDelay={0} precision={3} renderer={renderCircle} />
          </svg>
        </div>
        {
          accepted ? (
            <div className="counter__accepted">
              <svg viewBox="0 0 81 61" xmlns="http://www.w3.org/2000/svg">
                <path className="check" d="M78.875,7.75l-50.5,51.2c-1.7,1.8 -4.6,1.8 -6.3,-0l-20.8,-21.3c-1.7,-1.8 -1.7,-4.6 -0,-6.4c1.7,-1.8 4.6,-1.8 6.3,-0l17.7,18.1l47.4,-48c1.7,-1.8 4.6,-1.8 6.3,-0c1.7,1.8 1.7,4.6 -0.1,6.4Z"/>
              </svg>
            </div>
          ) : (
            <React.Fragment>
              <div className="counter__seconds">
                <Countdown date={Date.now() + counter} renderer={renderClock} />
              </div>
              <p className="counter__text">
                {accept === true ? "Accepting..." : "To accept"}
              </p>
            </React.Fragment>
          )
        }
      </div>
    )

  }

}

export default Counter;
