// modules
import React, { Component } from 'react';
// styles
import './eyebrow.scss';

class Eyebrow extends Component {

  render() {

    const {
      text
    } = this.props;

    return <div className="eyebrow">{text}</div>;

  }

}

export default Eyebrow;
