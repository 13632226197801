// modules
import React from 'react';

const Declined = () => {
  return (
    <div className="container container--full container--dark">
      <h1>Order declined</h1>
    </div>
  )
}

export default Declined;
