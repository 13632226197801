// modules
import React, { Component } from 'react';
// components
import Timer from '../molecules/Timer';
import Eyebrow from '../atoms/Eyebrow';
import Meal from '../molecules/Meal';
import Restaurant from '../molecules/Restaurant';
// data
import { order } from '../../data/order';
// styles
import './order.scss';

class Order extends Component {

  state = {
    isLoading: true,
    order: order
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 2000);
  }

  render() {
    
    const {
      id,
      pick_up_instructions,
      items
    } = this.state.order;

    const {
      isLoading
    } = this.state;

    return (
      <React.Fragment>
        {
          isLoading ? (
            <div className="page-loader"></div>
          ) : (
            <React.Fragment>
              <Timer time={order}/>
              <main className="container">
                <section className="section section--order">
                  <h3 className="section__title section__title--icon">
                    <sub className="icon icon--loading"></sub>
                    <span>Collect order #{id}</span>
                  </h3>
                  <Eyebrow text="Pick up instructions" />
                  <p className="section__paragraph">{pick_up_instructions}</p>
                  <ul className="section--order__meals">
                    {
                      items.map((meal, index) => {
                        return (
                          <li key={index}>
                            <Meal meal={meal}/>
                          </li>
                        )
                      })
                    }
                  </ul>
                </section>
              </main>
              <Restaurant restaurant={order}/>
            </React.Fragment>
          )
        }
      </React.Fragment>
    )
  }
}

export default Order;
