// modules
import React, { Component } from 'react';
// components
import Button from '../atoms/Button';
// styles
import './footer.scss';

class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <footer className="container footer">
          <Button href="/" target="_self" classes="button--primary" text="Ready to deliver" />
        </footer>
        <div className="footer-after"></div>
      </React.Fragment>
    )
  }
}

export default Footer;
