// modules
import React from 'react';
// components
import Header from '../molecules/Header';
import Order from '../organisms/Order';
import Footer from '../molecules/Footer';

const Collect = () => {
  return (
    <React.Fragment>
      <Header />
      <Order />
      <Footer />
    </React.Fragment>
  )
}

export default Collect;
