// modules
import React, { Component } from 'react';
import ClickNHold from 'react-click-n-hold';
// components
import Counter from '../atoms/Counter';

class Delivery extends Component {

  // interval hold counter
  intervalHC = 0;

  constructor(props) {
    super(props);

    this.state = {
      original: 30000,
      counter: 30000,
      accept: false,
      accepted: false,
      holdLength: 0,
      time: 2
    }
  }

  handleStart = (event) => {
    let saveCounter = document.querySelector('.counter__seconds span').textContent;
    
    saveCounter = saveCounter.split('');
    if (saveCounter[0] === '0') {
      saveCounter = parseFloat(saveCounter[1]) * 1000;
    } else {
      saveCounter = (parseFloat(saveCounter[0]) * 10000) + (parseFloat(saveCounter[1]) * 1000);
    }

    this.setState({
      counter: saveCounter,
      accept: true
    });

    let holdCounter = 0;
    this.intervalHC = setInterval(() => {
      holdCounter = holdCounter + 1;
      this.setState({ holdLength: holdCounter });
    }, 1);
  }

  handleEnd = (event, enough) => {
    this.setState({ accept: false });
    clearInterval(this.intervalHC);
  }

  handleHold = (event) => {
    this.setState({ accepted: true });
    setTimeout(() => {
      this.props.history.push('./order-collect');
    }, 1000);
  }

  handleDecline = (event) => {
    this.props.history.push('./order-declined');
  }

  render () {

    // disable browser context menu (triggered on a long press)
    window.oncontextmenu = function() { return false; }

    const {
      original,
      counter,
      accept,
      accepted,
      holdLength,
      time
    } = this.state;

    const {
      history
    } = this.props;

    return (
      <div className="container container--full container--dark">
        <h1>{accepted ? "Let's go!" : "New delivery"}</h1>
        <Counter original={original} counter={counter} accept={accept} accepted={accepted} hold={holdLength} time={time} history={history} />
        { !accepted ?
          <React.Fragment>
            <div className="button-group">
              <ClickNHold time={time} onClickNHold={this.handleDecline}>
                <button className="longpress longpress--decline"><span>Decline</span></button>
              </ClickNHold>
              <ClickNHold time={time} onStart={this.handleStart} onEnd={this.handleEnd} onClickNHold={this.handleHold}>
                <button className="longpress longpress--accept"><span>Accept</span></button>
              </ClickNHold>
            </div>
            <p>Press and hold for a moment to accept</p>
          </React.Fragment>
          : <p className="bold loading">Loading your delivery</p>
        }
        
      </div>
    )

  }

}

export default Delivery;
