// modules
import React, { Component } from 'react';
// components
import Link from '../atoms/Link';
// assets
import ShopIcon from '../../assets/shop.png';
// styles
import './header.scss';

class Header extends Component {
  render() {
    return (
      <React.Fragment>
        <header className="container header">
          <div className="header__icon">
            <img src={ShopIcon} alt="Collect the order" />
          </div>
          <h3 className="header__title">Collect the order</h3>
          <Link href="/" target="_self" classes="header__link" text="Help" />
        </header>
        <div className="header-after"></div>
      </React.Fragment>
    )
  }
}

export default Header;
