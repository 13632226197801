// modules
import React, { Component } from 'react';
// styles
import './link.scss';

class Link extends Component {

  render() {

    const {
      href,
      classes,
      target,
      text
    } = this.props;

    return (
      <a
        href={href}
        target={target}
        rel={target !== "_blank" ? "" : "external noopener noreferrer"}
        className={"link " + classes}>
          {text}
      </a>
    )

  }

}

export default Link;
