export const order = {
  "id": "093",
  "restaurant_name": "Restaurant Rondebosch",
  "restaurant_address": "51 Main Rd, Rondebosch, Cape Town, 7700",
  "restaurant_directions": "https://www.google.co.za/maps/place/51+Main+Rd,+Rondebosch,+Cape+Town,+7700/@-33.9604292,18.4682005,17z/data=!3m1!4b1!4m5!3m4!1s0x1dcc5d2641d9433f:0xba79d11cc2c20fbd!8m2!3d-33.9604292!4d18.4703892",
  "restaurant_number": "0211234567",
  "pick_up_instructions": "Go to counter 1 on the left where the sign for ‘all pickups’ is",
  "prep_time": 20000,
  "items": [
    {
      "name": "Cheese Burger",
      "details": "Large Soda, Large Fries"
    },
    {
      "name": "Beef Burger",
      "details": "Still Water, Large Fries"
    },
    {
      "name": "Pepperoni Pizza",
      "details": null
    },
    {
      "name": "Small Flake Ice Cream",
      "details": null
    },
    {
      "name": "Green Salad",
      "details": "Thousand Island Sauce"
    },
    {
      "name": "Beef Burger",
      "details": "Large Soda"
    }
  ]
}