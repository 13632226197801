// modules
import React, { Component } from 'react';
// components
import Button from '../atoms/Button';
// styles
import './restaurant.scss';

class Restaurant extends Component {

  render() {

    // restaurant details
    const {
      restaurant_name,
      restaurant_address,
      restaurant_directions,
      restaurant_number
    } = this.props;

    return (
      <section className="section section--restaurant">
        <h3 className="section__title">{restaurant_name}</h3>
        <p className="section__paragraph">{restaurant_address}</p>
        <div className="button-group">
          <Button href={restaurant_directions} target="_blank" classes="button--primary button--ghost button--icon button--directions" text="Directions" />
          <Button href={"tel:" + restaurant_number} target="_blank" classes="button--primary button--ghost button--icon button--call" text="Call" />
        </div>
      </section>
    )

  }

}

export default Restaurant;
