// modules
import React, { Component } from 'react';
import Countdown from 'react-countdown';
// components
import Eyebrow from '../atoms/Eyebrow';
// styles
import './timer.scss';

class Timer extends Component {

  state = {
    colors: {
      minor: "#00C853",
      critical: "#FF2500"
    }
  }

  render() {

    const {
      colors
    } = this.state;

    // preparation time
    const {
      prep_time
    } = this.props.time;

    // renderClock callback with conditions
    const renderClock = ({ hours, minutes, seconds, completed }) => {
      if (completed) {
        document.querySelector('.footer').classList.add('footer--active');
        document.querySelector('.footer-after').classList.add('footer-after--active');
        document.querySelector('.section--order .icon').classList.remove('icon--loading');
        document.querySelector('.section--order .icon').classList.add('icon--success');
        return <span>The order is ready!</span>;
      } else {
        if (hours !== 0) {
          return <span>{hours < 10 ? '0' + hours : hours}:{minutes < 10 ? '0' + minutes : minutes}:{seconds < 10 ? '0' + seconds : seconds}</span>;
        } else {
          return <span>{minutes < 10 ? '0' + minutes : minutes}:{seconds < 10 ? '0' + seconds : seconds}</span>;
        }
      }
    };

    // renderBar callback
    const renderBar = ({ total, completed }) => {
      let percentage = 100 - ((total / prep_time) * 100);
      return (
        <div className="progress-bar__tracker" style={{width: percentage + "%", backgroundColor: completed ? colors.minor : colors.critical}}></div>
      )
    };

    return (
      <section className="section section--timer">
        <h3 className="section__title">
          <Countdown date={Date.now() + prep_time} renderer={renderClock} />
        </h3>
        <Eyebrow text="Minutes remaining" />
        <div className="progress-bar">
          <Countdown date={Date.now() + prep_time} intervalDelay={0} precision={3} renderer={renderBar} />
        </div>
      </section>
    )

  }

}

export default Timer;
